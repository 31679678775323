import React from 'react'
import Helmet from 'react-helmet'
import Layout from "../components/layout"

const Success = (props) => (
  <Layout>
    <div>
        <Helmet>
            <title>Success Page</title>
            <meta name="description" content="Success Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Newsletter</h2>
                    </header>
                    <p>Coming soon! Stay tuned. ☺ </p>
                </div>
            </section>
        </div>

    </div>
  </Layout>
)

export default Success